import React, {useEffect} from "react"
import "styles/style.scss"
import { graphql } from "gatsby"

import {IHomeRequest} from "interfaces"
import ArticleGridCmp, {ArticleRowCmp} from 'components/ArticleGrid'
import {Layout, MagazineCard, Carousel, MagAvailAtJelly} from 'components'
import {Issue, Page, ArticleCarousel, ArticleGrid, ArticleRow, ArticleSplitter} from 'types'

const aboutText = [
  "Suddenly Saskatchewan Magazine highlights the uniqueness of Saskatchewan,  and its sudden rise in popularity and growth mentioned in publications such as USA Today and the New York Times. ",
  "Advertorials and Articles focus on its rare & particular tourism, its passionate sports, its character, and the prosperous opportunity for businesses and artists influenced by a Saskatchewan setting. ",
  "It is centred in Saskatoon, but contributors range from Lac La Ronge in the North, to provincial boundaries east and west, to the Outlaw Caves near the US border."
]
const magAvailLink = "https://www.dogslifespa.ca/about"
const newIssueName = 'fall-2023'

const IndexPage = ({ data }: { data: IHomeRequest }) => {

  const newestIssue: Issue = new Issue(newIssueName)
  newestIssue.setImgProps(data.newestIssue.childImageSharp)
  const magAvailImgProps = data.dogsLifeImg.childImageSharp.fluid
  const articles = ArticleSplitter.createAndSeparateArticles(data.allMarkdownRemark.edges)

  const carousel = new ArticleCarousel(articles['carousel'])
  const row = new ArticleRow(articles['other'])
  
  const saskTourism = new ArticleGrid(
    articles['saskTourism'],
    false
  )
  
  
  const sports = new ArticleGrid(
    articles['sports'],
    true
  )
  
  
  const thingsToDo = new ArticleGrid(
    articles['thingsToDo'],
    false
  )
  
  
  const health = new ArticleGrid(
    articles['health'],
    true
  )

  data.site.siteMetadata.title = "Suddenly Saskatchewan Magazine"
  data.site.siteMetadata.image = data.shareImage.childImageSharp.fixed.src
  
  useEffect(() => {
    const url = window.location.href.split('#')[1]  
    if (url){
      document.getElementById(url).scrollIntoView();
    }
    //   window.scrollTo(0,0)
    // };
  })

  return (
    <Layout siteMetadata={data.site.siteMetadata} page={Page.home}>
      <div className='page p-md-5 p-2' data-spy="scroll" data-target="#navbar-example2" data-offset="0">
        <div className="row w-100">
          <div className='col-lg-6 p-0 mb-5 flex-center-col'>
            <p className='mt-lg-0 mt-4 mb-5 text-center'>
              <span>{aboutText[0]}</span>
              <span>{aboutText[1]}</span>
              <span>{aboutText[2]}</span>
            </p>
            <MagazineCard className='w-lg-50 w-70' issue={newestIssue} />
          </div>
          <div className='col-lg-6 p-0 flex-center-col'>
            <Carousel className='w-100' car={carousel} />
            <MagAvailAtJelly
              link={magAvailLink}
              imgProps={magAvailImgProps}
              alt="It's a dogs life Logo"
              className={'flex-center-row w-100'}
            />
          </div>
        </div>
        <ArticleRowCmp row={row} className='mb-5 mt-5' />
        <ArticleGridCmp id="saskTourism" className="w-100" grid={saskTourism} />
        <ArticleGridCmp id="sports" className='w-100 pt-5' grid={sports} />
        <ArticleGridCmp id="thingsToDo" className='w-100 pt-5' grid={thingsToDo} />
        <ArticleGridCmp id="health" className='w-100 pt-5' grid={health} />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query HomePageQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subTitle
            date(formatString: "MMMM DD, YYYY")
            description
            category
            subCategory
            givenRank
            author
            featured
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
    dogsLifeImg: file(
      extension: {eq: "png"},
      name: {eq: "its-a-dogs-life"}
    ){
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    
    newestIssue: file(
      extension: {eq: "jpg"},
      name: {eq: "fall-2023"}
    ){
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    shareImage: file(
      extension: {eq: "jpg"},
      name: {eq: "homepage"}
    ){
      childImageSharp {
        fixed(width: 800) {
          src
        }
      }
    }
    site {
      siteMetadata {
        author
        authorDescription
        description
        title
        themeColor
        siteUrl
      }
    }
  }
`

export default IndexPage